import React from "react";

import Layout from "../templates/Layout/Layout";

const NotFoundPage = () => (
  <Layout>
    <h1
      style={{
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        "margin-top": "25%",
      }}
    >
      This page does not exist{" "}
    </h1>
  </Layout>
);

export default NotFoundPage;
